"use client";
import { Button } from "@/components/atoms";
import Close from "@/icons/Close";
import useModal from "@/lib/hooks/useModal";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useCallback, useEffect, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import styles from "./modal.module.css";

import { LOCATION_SETTINGS_MODAL } from "@/components/atoms/LocationSelector/LocationSelectorPopover";
import useLocation from "@/lib/hooks/useLocation";

const Modal = ({
  children,
  modal,
  className,
  always,
  animateFromTop,
  unstyled,
  toolbar = true,
  title,
  market = false,
  footer = false,
}: any) => {
  const { isOpen, closeModal } = useModal(modal, () => {});
  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const t = useTranslations();

  const handleDocumentClick = useCallback(
    (e: any) => {
      if (!isOpen) return;
      if (modalRef.current?.contains(e.target) || e.target === modalRef.current)
        return;
      closeModal();
    },
    [isOpen, closeModal, modalRef],
  );

  // cannot use a regular useEventListener here because of the cleanup on if(!isOpen)
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleDocumentClick, {
        capture: true,
      });
    } else {
      document.removeEventListener("click", handleDocumentClick, {
        capture: true,
      });
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick, {
        capture: true,
      });
    };
  }, [isOpen, handleDocumentClick]);

  // // On Link press inside modal we close it
  useEffect(() => {
    const { current: modal } = modalRef;
    if (!isOpen || !modal) return;

    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest("a")) {
        closeModal();
      }
    };

    modal.addEventListener("click", handleClick);

    return () => {
      modal.removeEventListener("click", handleClick);
    };
  }, [isOpen, closeModal]);

  // Any is used here because of the inert attribute being directly wrong in @types/react
  const a11yProps = isOpen ? {} : ({ "aria-hidden": true, inert: "" } as any);

  if (isOpen || always) {
    return (
      <div
        data-open={isOpen}
        ref={modalRef}
        className={clsx(
          styles.modal,
          styles[modal],
          footer && styles.footer,
          "modal",
          unstyled ? null : styles.modalGeneral,
          isOpen ? styles.open : null,
          className,
        )}
        {...a11yProps}
      >
        <div className={styles.modal__container}>
          <div className={styles.modalInner}>
            {toolbar && (
              <div className={styles.modal__toolbar}>
                {title && <span className={styles.title}>{title}</span>}
                <div className={styles.modal__toolbar_actions}>
                  {market && (
                    <Button modal={LOCATION_SETTINGS_MODAL} color="transparent">
                      <span>
                        <ReactCountryFlag
                          alt={location?.location}
                          countryCode={"" + location?.location}
                          svg
                        />
                        <span>{t("footer.location.button.change")}</span>
                      </span>
                    </Button>
                  )}
                  <Button
                    onClick={closeModal}
                    color="transparent"
                    rounded
                    label="close"
                  >
                    <Close />
                  </Button>
                </div>
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Modal;
